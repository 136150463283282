<template>
    <div v-loading.fullscreen.lock="loading">
        <shop-back-search-bar-component
            AddAuth="新增背景墙"
            :AllState="AllState"
            :AllType="AllType"
            @search="get_shop_back_index"
            @addTeam="addTeam"
        ></shop-back-search-bar-component>
        <el-descriptions
            direction="vertical"
            :column="8"
            :labelStyle="{'text-align':'center','background-color':'#e8edf2','width':'12.5%'}"
            :contentStyle="{'text-align': 'center','background-color':'#e8edf2'}"
            :colon="false"
            border
        >
            <el-descriptions-item v-for="item in res_data" :key="item.name">
                <template slot="label">
                    <span></span>
                </template> 
                <transition name="el-zoom-in-center">
                    <img
                        style="width: 100%;border-radius: 10px;"
                        :src="item['picture'][0]['url']"
                        @click="details_shop_back(item.id)" 
                        v-show="transition_box"
                    />
                </transition>
                <div style="font-family: HarmonyOS_Sans_Black;">
                    <span v-if="item.type === 1">{{ item.name }}</span>
                    <span v-else-if="item.type === 2" style="color: #6cb4ff;">{{ item.name }}</span>
                    <span v-else-if="item.type === 3" style="color: #FF0000;">{{ item.name }}</span>
                </div>
            </el-descriptions-item>
            <el-descriptions-item v-for="i in res_buchong" :key="i+'bc'">
                <template slot="label">
                    <span></span>
                </template>
                <div style="width: 100%"></div>
            </el-descriptions-item>
        </el-descriptions>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="total"
            layout="total, prev, pager, next, jumper"
            @search="get_shop_back_index"
        ></common-page-component>
        <shop-back-edit-component
            edit_auth="修改背景墙"
            del_auth="删除背景墙"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :shop_back_details_data="fshop_back_details_data"
            :AllState="AllState"
            :AllType="AllType"
            :UserItems="UserItems"
            :is_have_back="is_have_back"
            @exitShopBackDialog="dialogExit"
            @search="get_shop_back_index"
            @show_edit="show_edit"
            @cancel_edit="cancel_edit"
            @details_row="details_shop_back"
            @RefreshItems="get_user_items_index()"
        ></shop-back-edit-component>
    </div>
</template>

<script>
import { ShopBackIndexRequest,ShopBackDetailsRequest } from '@/network/shop/ShopBack.js'
import { UserItemsRequest } from '@/network/user.js'

import ShopBackSearchBarComponent from '@/components/shop/ShopBack/ShopBackSearchBarComponent'
import ShopBackEditComponent from '@/components/shop/ShopBack/ShopBackEditComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'

export default {
    name:'',
    data(){
        return {
            transition_box: false,
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            total: 0,
            cond: {
                state: 1,
                name: '',
                type: '',
                page: 1,
                limit: 16
            },
            res_data: [],
            res_buchong: 0,
            fshop_back_details_data: {},
            AllState:[
                {id:0,name:'下架'},
                {id:1,name:'正常'}
            ],
            AllType:[
                {id:1,name:'普通'},
                {id:2,name:'限定'},
                {id:3,name:'专属'}
            ],
            UserItems: {},
            is_have_back: true
        }
    },
    computed:{},
    methods:{
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        cancel_edit() {
            this.edit_type = 0
        },
        get_shop_back_index(param = {}) {
            this.loading = true
            this.transition_box = false
            this.cond.state = param.state ?? this.cond.state
            this.cond.name = param.name ?? this.cond.name
            this.cond.type = param.type ?? this.cond.type
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            ShopBackIndexRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.res_data = s.result.data
                        this.total = s.result.total
                        const yushu = this.res_data.length % 8 === 0 ? 8 : this.res_data.length % 8
                        this.res_buchong = 8 - yushu
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
                .finally(() => this.transition_box = true)
        },
        details_shop_back(id) {
            this.fid = id
            this.fdialogFormVisible = true
            ShopBackDetailsRequest(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fshop_back_details_data = s.result.data
                        this.is_have_back = s.result.is_have_back
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        get_user_items_index() {
            UserItemsRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.UserItems = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_shop_back_index()
        this.get_user_items_index()
    },
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        ShopBackSearchBarComponent,
        ShopBackEditComponent,
        CommonPageComponent
    },
    watch:{},
}
</script>

<style lang='less' scoped></style>