import request from '../request'

//背景墙列表
export const ShopBackIndexRequest = p => {
    return request({
        method:'GET',
        url:'ShopBack/index',
        params: p
    })
}

//角色框序列
export const ShopBackListRequest = () => {
    return request({
        method:'GET',
        url:'ShopBack/list'
    })
}

//添加背景墙
export const ShopBackAddRequest = d => {
    return request({
        method:'POST',
        url:'ShopBack/add',
        data: d
    })
}

//删除背景墙
export const ShopBackDelRequest = id => {
    return request({
        method:'DELETE',
        url:'ShopBack/del',
        data: {
            id: id
        }
    })
}

//修改背景墙
export const ShopBackEditRequest = data => {
    return request({
        method:'PUT',
        url:'ShopBack/edit',
        data
    })
}

//获取背景墙详情
export const ShopBackDetailsRequest = id => {
    return request({
        method:'GET',
        url:'ShopBack/details',
        params: {
            id
        }
    })
}